'use client'

import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import {
  Flex,
  Box,
  Text,
  Heading,
  FormControl,
  FormLabel,
  Switch,
  Input,
  Select,
  CheckboxGroup,
  SimpleGrid,
  Checkbox,
  Button,
  Divider,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  RadioGroup,
  Radio,
  Stack
} from '@chakra-ui/react';
import { useGeneralContext } from '../../App';
import { STATES_LIST, COLLECTIONS_LIST, PAGE_CONFIG, MONTHS_LIST } from '../../utils/constants';
import { ProgressBar } from '../../components/ProgressBar';
import { Confirmation } from '../../components/Confirmation';
import { Pagination } from '../../components/Pagination';
import { FormModal } from '../../components/FormModal';
import { getErrorMessage } from '../../utils/utils';

export const SelectFlex = () => {
  const [pageId, setPageId] = useState<string>('');
  const [isInvalidUrlPattern, setIsInvalidUrlPattern] = useState<boolean>(true);
  const [selectedState, setSelectedState] = useState<string>('Alabama');
  const [selectedCities, setSelectedCities] = useState<Array<string>>([]);
  const [newCity, setNewCity] = useState<string>('');
  const [allCities, setAllCities] = useState<any>(null);
  const [selectAllItems, setSelectAllItems] = useState<boolean>(false);
  const [isReadyToProcess, setIsReadyToProcess] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const navigate = useNavigate();

  const {
    config,
    environment,
    setEnvironment,
    collection,
    setCollection,
    operationType,
    setOperationType,
    stateToProcess,
    setStateToProcess,
    cityToProcess,
    setCityToProcess,
    pageData,
    setPageData,
    permalinkStyle,
    setPermalinkStyle,
    urlPattern,
    setUrlPattern,
    itemsToReplicate,
    setItemsToReplicate,
    setResultPages,
    setCurrentProcess,
    monthToReplace,
    setMonthToReplace,
    currentDateFilter,
    setCurrentDateFilter,
    newMonth,
    setNewMonth,
    newDateFilter,
    setNewDateFilter,
    modifyPageDate,
    setModifyPageDate
  } = useGeneralContext();

  useEffect(() => {
    setDateValues();
    setEnvironment('dev');
    setCollection('STRAPI_AUTO_OTHER');
    setStateToProcess('Alabama');
    setPageId('');
    setPermalinkStyle('complete');
    setUrlPattern('');
    setOperationType('state');
    setResultPages([]);
    setCurrentProcess('pageDuplication');
    getCitiesFromDB();
  }, []);

  useEffect(() => {
    config.current = PAGE_CONFIG[environment];
  }, [environment]);

  useEffect(() => {
    setPageData(null);
    setSelectAllItems(false);
    setItemsToReplicate([]);
    setSelectedState('Alabama');
    setSelectedCities([]);
    setUrlPattern('');

    if (allCities) {
      setErrorMessage('');
    }
  }, [environment, collection, pageId, operationType, stateToProcess]);

  useEffect(() => {
    setIsReadyToProcess(pageData && !isInvalidUrlPattern && Boolean(itemsToReplicate.length));
    setSelectAllItems(itemsToReplicate.length === Object.keys(STATES_LIST).length - 1);
  }, [isInvalidUrlPattern, itemsToReplicate]);

  useEffect(() => {
    if (operationType === 'city') {
      setIsInvalidUrlPattern(urlPattern.includes(' ') || !urlPattern?.startsWith('/') || !urlPattern?.endsWith('/') || !urlPattern?.includes('{{state}}') || !urlPattern?.includes('{{city}}'));
    } else {
      setIsInvalidUrlPattern(urlPattern.includes(' ') || !urlPattern?.startsWith('/') || !urlPattern?.endsWith('/') || !urlPattern?.includes('{{state}}'));
    }
  }, [urlPattern]);

  useEffect(() => {
    setCityToProcess(allCities?.[stateToProcess]?.[0] || '');
  }, [stateToProcess]);

  const getCitiesFromDB = async () => {
    try {
      const request = await axios.get(`/api/cities`);
      if (request?.data && Array.isArray(request.data) && Boolean(request.data.length)) {
        const citiesList = request.data.reduce((acc, item) => {
          const { name, state } = item;
          if (!acc[state]) {
            acc[state] = [name];
          } else {
            acc[state].push(name);
          }
          return acc;
        }, {});
        setAllCities(citiesList);
      } else {
        throw new Error('No cities found');
      }
    } catch (error) {
      setErrorMessage(`It was not possible to get the list of cities from DB. ${getErrorMessage(error)}`);
    }
  };

  const addCityToDB = async () => {
    try {
      const request = await axios.post(`/api/cities`, { name: newCity, state: selectedState });
      
      if (request?.data?.id) {
        getCitiesFromDB();
        setNewCity('');
      } else {
        throw new Error('The new city was not created');
      }
    } catch (error) {
      error.message = `It was not possible to add the new city to the DB. ${getErrorMessage(error)}`;
      throw error;
    }
  };

  const setDateValues = () => {
    const currentDate = new Date();
    const nextMonth = new Date();
    nextMonth.setMonth(currentDate.getMonth() + 1);
    setMonthToReplace(currentDate.toLocaleString('en-US', { month: 'long' }));
    setNewMonth(nextMonth.toLocaleString('en-US', { month: 'long' }));

    currentDate.setDate(1);
    currentDate.setMonth(currentDate.getMonth() - 2);
    setCurrentDateFilter(currentDate);

    nextMonth.setDate(1);
    nextMonth.setMonth(nextMonth.getMonth() - 2);
    setNewDateFilter(nextMonth);
  };

  const handlePageSubmit = async () => {
    setIsProcessing(true);
    const message = `It was not possible to identify the page #${pageId} for the collection: ${COLLECTIONS_LIST[collection].name}.
    Please check collection name, ID, and selected state and/or city.`;
    try {
      const request = await axios.get(`${config.current.strapiUrl}/api/${COLLECTIONS_LIST[collection].api}/${pageId}?pLevel=6&status=draft`, {
        headers: {
          Authorization: `Bearer ${config.current.token}`
        }
      });
      const templateData = request.data?.data;
      const permalinkControl = operationType === 'city' ? cityToProcess : stateToProcess;
      if (!templateData || !templateData.publishing.permalink.includes(permalinkControl.replaceAll(" ", "-").toLowerCase())) {
        setPageData(null);
        setErrorMessage(message);
      } else {
        setPageData(templateData);
        setErrorMessage('');
      }
    } catch {
      setErrorMessage(message);
    } finally {
      setIsProcessing(false);
    }
  };

  const onClickCity = (state, city, wasChecked) => {
    if (wasChecked) {
      setItemsToReplicate((prev) => [...prev, { state: state, city: city }]);
      setSelectedCities((prev) => [...prev, `${city}, ${state}`]);
    } else {
      setItemsToReplicate((prev) => prev.filter(item => `${item.city}, ${item.state}` !== `${city}, ${state}`));
      setSelectedCities((prev) => prev.filter(item => item !== `${city}, ${state}`));
    }
  };

  const handleProcess = () => {
    const abbreviatedState = STATES_LIST[stateToProcess];
    const stateValue = permalinkStyle === 'complete' ? stateToProcess.toLowerCase() : abbreviatedState;
    const formattedState = stateValue.toLowerCase().replaceAll(' ', '-');
    const formattedCity = cityToProcess.toLowerCase().replaceAll(' ', '-');
    setUrlPattern(operationType === 'city' ? urlPattern.replace('{{state}}', formattedState).replace('{{city}}', formattedCity) : urlPattern.replace('{{state}}', formattedState));
    navigate('/components/static');
  };

  const renderDateReplacer = () => {
    return (<>
      <Flex alignItems="center" gap={3} mb={6}>
        <Text fontWeight="semibold">
          Modify date values?
        </Text>
        <Switch
          id="modify-dates"
          isChecked={modifyPageDate}
          onChange={(event) => setModifyPageDate(event.target.checked)}
        />
      </Flex>
      {modifyPageDate && (
        <Flex alignItems="flex-start" justifyContent="center" gap="5%" mb={6} h="68px">
          <Box>
            <FormLabel htmlFor="current-month" mb={1}>
              Month to replace
            </FormLabel>
            <Select id="current-month" value={monthToReplace} onChange={(e) => setMonthToReplace(e.target.value)}>
              {MONTHS_LIST.map((item, index) => <option key={`replace-month-${index}`} value={item}>
                {item}
              </option>)}
            </Select>
          </Box>
          <Box>
            <FormLabel htmlFor="current-date" mb={1}>
              Current date filter value
            </FormLabel>
            <DatePicker
              id="current-date"
              dateFormat="yyyy-MM-dd"
              selected={currentDateFilter}
              onChange={(date) => setCurrentDateFilter(date)} />
          </Box>
          <Divider orientation="vertical" />
          <Box>
            <FormLabel htmlFor="new-month" mb={1}>
              New month value
            </FormLabel>
            <Select id="new-month" value={newMonth} onChange={(e) => setNewMonth(e.target.value)}>
              {MONTHS_LIST.map((item, index) => <option key={`new-month-${index}`} value={item}>
                {item}
              </option>)}
            </Select>
          </Box>
          <Box>
            <FormLabel htmlFor="new-date" mb={1}>
              New date filter value
            </FormLabel>
            <DatePicker
              id="new-date"
              dateFormat="yyyy-MM-dd"
              selected={newDateFilter}
              onChange={(date) => setNewDateFilter(date)} />
          </Box>
        </Flex>
      )}
    </>);
  };

  const renderURLBuilder = () => {
    const abbreviatedState = STATES_LIST[stateToProcess];
    const stateValue = permalinkStyle === 'complete' ? stateToProcess : abbreviatedState;

    return (<>
      <Text fontWeight="semibold" mb={6}>
        {`Please use {{state}}${operationType === 'city' ? ' and {{city}}' : ''} on the URL pattern to create the new permalink structure.`}
      </Text>
      <Flex mb={6} alignItems="center" gap="2%">
        <Box width="78%">
          <FormLabel htmlFor="base-url" mb={1}>
            URL Pattern
          </FormLabel>
          <Input
            id="base-url"
            isInvalid={isInvalidUrlPattern}
            errorBorderColor="red.300"
            value={urlPattern}
            onChange={(e) => setUrlPattern(e.target.value)}
          />
        </Box>
        <Box width="20%">
          <FormLabel htmlFor="permalink-style" mb={1}>
            How to show state
          </FormLabel>
          <Select id="permalink-style" value={permalinkStyle} onChange={(e) => setPermalinkStyle(e.target.value)}>
            <option value="complete">Complete</option>
            <option value="abbreviated">Abbreviated</option>
          </Select>
        </Box>
      </Flex>
      <Flex gap={2} mb={6}>
        <Text fontWeight="semibold">
          New permalink estructure:
        </Text>
        {urlPattern && (
          <Text fontWeight="semibold" color={isInvalidUrlPattern ? 'red.300' : '#2F855A'}>
            {operationType === 'city' ?
              urlPattern.replace('{{state}}', stateValue.toLowerCase().replaceAll(' ', '-')).replace('{{city}}', cityToProcess.toLowerCase().replaceAll(' ', '-')) :
              urlPattern.replace('{{state}}', stateValue.toLowerCase().replaceAll(' ', '-'))
            }
          </Text>
        )}
      </Flex>
    </>);
  };

  const renderContent = () => {
    if (operationType === 'city') {
      return (
        <Box>
          <Alert status="success" mb={6}>
          <AlertIcon />
          <AlertTitle>Success:</AlertTitle>
            <AlertDescription>{`A page for ${cityToProcess}, ${stateToProcess} was found on the ${collection} collection`}</AlertDescription>
          </Alert>
          <Flex mb={6} justifyContent="space-between">
            <Flex alignItems="center">
              <Text fontWeight="semibold">Based on</Text>
              <Select w="200px" m={1} value={selectedState} onChange={(e) => setSelectedState(e.target.value)}>
                {Object.keys(STATES_LIST).map((state, index) => <option key={`state-list-${index}`} value={state}>{state}</option>)}
              </Select>
              <Text fontWeight="semibold">please select the cities to replicate from the list</Text>
            </Flex>
            <FormModal
              buttonLabel="Add a new city"
              title="Create new city on the DB"
              bodyDescription={`Please write the new city name to add to the ${selectedState} list.`}
              color="teal"
              onOpenModal={() => setNewCity('')}
              onClickEvent={() => addCityToDB()}
              disableEvent={!newCity}
            >
              <FormLabel htmlFor="new-city" mt={2} mb={1}>
                City name
              </FormLabel>
              <Input id="new-city" value={newCity} onChange={(e) => setNewCity(e.target.value)} />
            </FormModal>
          </Flex>
          <CheckboxGroup value={selectedCities}>
            <Pagination
              state={selectedState}
              allItems={allCities?.[selectedState].filter(city => city !== cityToProcess).sort()}
              itemsPerPage={50}
              onClickEvent={onClickCity} />
          </CheckboxGroup>
        </Box>
      );
    } else {
      const handleSelectAllStates = (selectAll) => {
        const allStates = Object.keys(STATES_LIST).filter(state => state !== stateToProcess);
        setSelectAllItems(selectAll);
        setItemsToReplicate(selectAll ? allStates : []);
      };

      return (
        <Box>
          <Alert status="success" mb={6}>
          <AlertIcon />
          <AlertTitle>Success:</AlertTitle>
            <AlertDescription>{`A page for ${stateToProcess} was found on the ${collection} collection`}</AlertDescription>
          </Alert>
          <Text fontWeight="semibold" mb={4}>
            Please select the states to replicate from the list
          </Text>
          <Flex alignItems="center" mb={6}>
            <FormLabel htmlFor="select-all" mb="0">
              Select all states?
            </FormLabel>
            <Switch
              id="select-all"
              isChecked={selectAllItems}
              onChange={(event) => handleSelectAllStates(event.target.checked)}
            />
          </Flex>
          <CheckboxGroup value={itemsToReplicate} onChange={(event) => setItemsToReplicate(event)}>
            <SimpleGrid minChildWidth="150px" spacing="15px">
              {Object.keys(STATES_LIST)
              .filter(state => state !== stateToProcess)
              .map((state, index) => <Checkbox key={`item-checkbox-${index}`} id={`item-checkbox-${index}`} value={state}>{state}</Checkbox>)}
            </SimpleGrid>
          </CheckboxGroup>
        </Box>
      );
    }
  };

  return (
    <Flex flexDirection="column" alignItems="center" h="full">
      <ProgressBar processName="pageDuplication" currentStep={0} />
      <Heading as="h1" textAlign="center" mb={10}>Select Template Page</Heading>
      <FormControl borderWidth="1px" rounded="lg" shadow="1px 1px 3px rgba(0,0,0,0.3)" p={6}>
        <Flex mb={6}>
          <FormLabel htmlFor="environment" mb="0">
            Please select the environment you want to work on:
          </FormLabel>
          <RadioGroup
            id="environment"
            defaultValue={environment}
            onChange={(event) => setEnvironment(event)}>
            <Stack direction="row" gap={3}>
              <Radio value="dev">Development</Radio>
              <Radio value="prod">Production</Radio>
            </Stack>
          </RadioGroup>
        </Flex>
        <Flex alignItems="flex-end" justifyContent="space-between" gap={operationType === 'city' ? '2.5%' : '5%'} mb={6}>
          <Box w={operationType === 'city' ? '22.5%' : '25%'}>
            <FormLabel htmlFor="collection" mb={1}>
              Collection Name
            </FormLabel>
            <Select id="collection" value={collection} onChange={(e) => setCollection(e.target.value)}>
              {Object.keys(COLLECTIONS_LIST).map((item, index) => <option key={`collection-${index}`} value={item}>
                {COLLECTIONS_LIST[item].name}
              </option>)}
            </Select>
          </Box>
          <Box w="10%">
            <FormLabel htmlFor="page-id" mb={1}>
              Page ID
            </FormLabel>
            <Input id="page-id" value={pageId} min={1} onChange={(e) => setPageId(e.target.value)}>
            </Input>
          </Box>
          <Box w={operationType === 'city' ? '10%' : '15%'}>
            <FormLabel htmlFor="page-type" mb={1}>
              Page Type
            </FormLabel>
            <Select id="page-type" value={operationType} onChange={(e) => setOperationType(e.target.value)}>
              <option value="state">State</option>
              <option value="city">City</option>
            </Select>
          </Box>
          <Box w="15%">
            <FormLabel htmlFor="selected-state" mb={1}>
              State to process
            </FormLabel>
            <Select id="selected-state" value={stateToProcess} onChange={(e) => setStateToProcess(e.target.value)}>
              {Object.keys(STATES_LIST).map((state, index) => <option key={`state-list-${index}`} value={state}>{state}</option>)}
            </Select>
          </Box>
          {operationType === 'city' && <Box w="15%">
            <FormLabel htmlFor="selected-city" mb={1}>
              City to process
            </FormLabel>
            <Select id="selected-city" value={cityToProcess} disabled={!allCities} onChange={(e) => setCityToProcess(e.target.value)}>
              {allCities?.[stateToProcess].sort().map((city, index) => <option key={`city-list-${index}`} value={city}>{city}</option>)}
            </Select>
          </Box>}
          <Button
            w="15%"
            variant="solid"
            colorScheme="gray"
            isLoading={isProcessing}
            isDisabled={Boolean(errorMessage)}
            onClick={() => handlePageSubmit()}>
            Confirm Page
          </Button>
        </Flex>
        {errorMessage && (
          <Alert status="error">
            <AlertIcon />
            <AlertTitle>Data error:</AlertTitle>
            <AlertDescription>
              {errorMessage}
            </AlertDescription>
          </Alert>
        )}
        {pageData && (<>
          <Divider mb={6} />
          {renderDateReplacer()}
          <Divider mb={6} />
          {renderURLBuilder()}
          <Divider mb={6} />
          {renderContent()}
        </>)}
      </FormControl>
      <Confirmation
        buttonLabel="Next step"
        bodyDescription={`Please check selected template page and states to duplicate before moving on.\n
        Template page: ${operationType === 'city' ? cityToProcess + ', ' : ''}${stateToProcess}.\n
        Number of copies to create: ${itemsToReplicate.length}`}
        shouldBeDisabled={!isReadyToProcess}
        onClickEvent={() => handleProcess()} />
    </Flex>
  );
};
