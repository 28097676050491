'use client'

import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Heading,
  Flex,
  Box,
  SimpleGrid,
  Divider,
  Text,
  UnorderedList,
  ListItem,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription
} from '@chakra-ui/react';
import { useGeneralContext } from '../../App';
import { ProgressBar } from '../../components/ProgressBar';
import { ComponentBox } from '../../components/ComponentBox';
import { Confirmation } from '../../components/Confirmation';
import { COLLECTIONS_LIST } from '../../utils/constants';
import { replaceMultipleStrings } from '../../utils/utils';

export const DynamicCCComponents = () => {
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [componentsToModify, setComponentsToModify] = useState<any>({
    headline: false,
    product: false,
    comparison: false
  });

  const {
    config,
    pageData,
    resultPages,
    setResultPages
  } = useGeneralContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!pageData || !resultPages.length) {
      navigate('/');
    }
  }, [pageData, resultPages]);

  const handleProcess = async () => {
    setIsProcessing(true);
    setErrorMessage('');
    try {
      const updatedFlexZones = await Promise.all(resultPages.map(page => new Promise((res) => res(getUpdatedPage(page)))));
      await Promise.all(updatedFlexZones.map(page => new Promise((res) => res(updateCopyPage(page.flex_zone, page.id)))));
      setResultPages(updatedFlexZones);
      navigate('/created');
    } catch(e) {
      setErrorMessage(e.response && e.response.headers || e.request || e.message);
    } finally {
      setIsProcessing(false);
    }
  };

  const onCheckboxChange = (name, value) => {
    setComponentsToModify({
      ...componentsToModify,
      [name]: value
    });
  };

  const onCheckAllChange = (name, value) => {
    setComponentsToModify({
      headline: value,
      product: value,
      comparison: value
    });
  };

  const getUpdatedPage = async (page) => {
    const newFlexZone = await Promise.all(page.flex_zone.map(component =>
      new Promise((res) => res(getUpdatedComponent(component, page.replace)))
    ));

    return { ...page, flex_zone: newFlexZone };
  };

  const getUpdatedComponent = async (component, replace) => {
    const { card_1, card_2 } = replace;
    const updatedComponent = { ...component };
    const replacements = {
      [card_1.originalFullName]: card_1.newName,
      [card_2.originalFullName]: card_2.newName,
      ...card_1.originalShortName && {
        [card_1.originalShortName]: card_1.newName
      },
      ...card_2.originalShortName && {
        [card_2.originalShortName]: card_2.newName
      }
    };
    delete updatedComponent.id;

    if (component['__component'] === 'page.headline' && componentsToModify.headline) {
      updatedComponent.text = replaceMultipleStrings(component.text, replacements);
      updatedComponent.subnav_text = replaceMultipleStrings(component.subnav_text, replacements, 35);
      updatedComponent.category_winner_text = replaceMultipleStrings(component.category_winner_text, replacements);
    } else if (component['__component'] === 'database.cc-product-card' && componentsToModify.product) {
      const newValues = {
        [card_1.originalValue]: card_1.newValue,
        [card_2.originalValue]: card_2.newValue
      };
      const updatedTitle = Boolean(component.title) && {
        ...component.title,
        text: replaceMultipleStrings(component.text, replacements),
        subnav_text: replaceMultipleStrings(component.subnav_text, replacements, 35),
        category_winner_text: replaceMultipleStrings(component.category_winner_text, replacements)
      };
      updatedComponent.title = updatedTitle || null;
      updatedComponent.credit_card = newValues[component.credit_card] || null;
    } else if (component['__component'] === 'database.cc-comparison-tool' && componentsToModify.comparison) {
      updatedComponent.credit_cards_1 = card_1.newValue;
      updatedComponent.credit_cards_2 = card_2.newValue;
      // updatedComponent.card1_compliance_cta_html = replaceMultipleStrings(component.card1_compliance_cta_html, replacements);
      // updatedComponent.card2_compliance_cta_html = replaceMultipleStrings(component.card2_compliance_cta_html, replacements);
      updatedComponent.our_take_html = replaceMultipleStrings(component.our_take_html, replacements);
    }

    return updatedComponent;
  };

  const updateCopyPage = async (newFlexZone, pageID) => {
    await axios.put(`${config.current.strapiUrl}/api/${COLLECTIONS_LIST.STRAPI_CREDIT_FLEX_PAGE.api}/${pageID}?status=draft&validation=skip`, { data: { flex_zone: newFlexZone } }, {
      headers: {
        Authorization: `Bearer ${config.current.token}`
      }
    });
  };

  return (
    <Flex flexDirection="column" alignItems="center" h="full">
      <ProgressBar processName="pageDuplication" currentStep={1} />
      <Heading textAlign="center" mb={10}> Select Dynamic Components</Heading>
      <Box w="full" borderWidth="1px" rounded="lg" shadow="1px 1px 3px rgba(0,0,0,0.3)" py={6} px={44}>
        {errorMessage && (
          <Alert status="error" mb={6}>
            <AlertIcon />
            <AlertTitle>Strapi error:</AlertTitle>
            <AlertDescription>
              {errorMessage}
            </AlertDescription>
          </Alert>
        )}
        {pageData && (
          <SimpleGrid gridTemplateColumns="repeat(3, 1fr)" gridGap={5}>
            <ComponentBox name="all" icon="select" onCheckboxChange={onCheckAllChange} />
            {pageData.flex_zone.some(component => component['__component'] === 'page.headline') && <ComponentBox
              name="headline"
              isSelectedByParent={componentsToModify.headline}
              onCheckboxChange={onCheckboxChange}
            />}
            {pageData.flex_zone.some(component => component['__component'] === 'database.cc-product-card') && <ComponentBox
              name="product"
              isSelectedByParent={componentsToModify.product}
              onCheckboxChange={onCheckboxChange}
            />}
            {pageData.flex_zone.some(component => component['__component'] === 'database.cc-comparison-tool') && <ComponentBox
              name="comparison"
              isSelectedByParent={componentsToModify.comparison}
              onCheckboxChange={onCheckboxChange}
            />}
          </SimpleGrid>
        )}
        <Divider my={6} />
        <Text fontWeight="bold" mb={4}>
          Data found on the template page
        </Text>
        {pageData && (
          <Box>
            <b>Headline: </b>
            <UnorderedList>
              {pageData.flex_zone.filter(component => component['__component'] === 'page.headline').map((item, index) => (
                <ListItem key={`headline-${index}`}>
                  {item.text}
                </ListItem>
              ))}
            </UnorderedList>
          </Box>
        )}
      </Box>
      <Confirmation
        buttonLabel="Update Pages"
        bodyDescription={`Please check template data and selected components to replace before moving on.\n
        Headline: ${componentsToModify.headline ? 'Yes' : 'No'}.\n
        CC Product Card: ${componentsToModify.product ? 'Yes' : 'No'}.\n
        CC Comparison Tool: ${componentsToModify.comparison ? 'Yes' : 'No'}.`}
        onClickEvent={() => handleProcess()}
        shouldBeLoading={isProcessing}
        shouldBeDisabled={false} />
    </Flex>
  );
};
