'use client'

import { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import {
  Flex,
  Box,
  Heading,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  Button,
  Divider,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  RadioGroup,
  Radio,
  Stack,
  Icon,
  Input
} from '@chakra-ui/react';
import { FiPlusCircle, FiMinusCircle } from 'react-icons/fi';
import { useGeneralContext } from '../../App';
import { COLLECTIONS_LIST, PAGE_CONFIG } from '../../utils/constants';
import { ProgressBar } from '../../components/ProgressBar';
import { Confirmation } from '../../components/Confirmation';

export const SelectCCTemplate = () => {
  const [pageId, setPageId] = useState<string>('');
  const [firstCard, setFirstCard] = useState<any>(null);
  const [secondCard, setSecondCard] = useState<any>(null);
  const [availableCards, setAvailableCards] = useState<Array<any>>([]);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [showErrorMessage, setShowErrorMessage] = useState<string>('');

  const {
    config,
    environment,
    setEnvironment,
    pageData,
    setPageData,
    setOperationType,
    itemsToReplicate,
    setItemsToReplicate
  } = useGeneralContext();

  useEffect(() => {
    setEnvironment('dev');
    setPageId('');
    setOperationType('cc-versus');
    setItemsToReplicate([]);
  }, []);

  useEffect(() => {
    config.current = PAGE_CONFIG[environment];
    getCCList();
  }, [environment]);

  useEffect(() => {
    setPageData(null);
    setShowErrorMessage('');
    setFirstCard(null);
    setSecondCard(null);
    setItemsToReplicate([]);
  }, [environment, pageId]);

  const getCCList = async () => {
    setIsProcessing(true);
    try {
      await axios.get(`${config.current.strapiUrl}/api/credit-cards-infos?fields[0]=name&fields[1]=short_name&populate[0]=card_image&pagination[start]=0&pagination[limit]=1000`, {
        headers: {
          Authorization: `Bearer ${config.current.token}`
        }
      }).then(response => {
        const cardsList = response.data?.data || [];
        const sortedList = cardsList.sort((a, b) => {
          if (a.attributes?.name < b.attributes?.name) {
            return -1;
          }
          if (a.attributes?.name > b.attributes?.name) {
            return 1;
          }
          return 0;
        });
        setAvailableCards(sortedList || []);
      });
    } catch {
      setShowErrorMessage('Error fetching the credit cards list. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  const handlePageSubmit = async () => {
    setIsProcessing(true);
    try {
      await axios.get(`${config.current.strapiUrl}/api/${COLLECTIONS_LIST.STRAPI_CREDIT_FLEX_PAGE.api}/${pageId}?status=draft&pLevel=6`, {
        headers: {
          Authorization: `Bearer ${config.current.token}`
        }
      }).then(response => {
        const templateData = response.data?.data;
        const isVersusPage = templateData.page_type === 'Versus' && templateData.versus_cards?.card_1 && templateData.versus_cards?.card_2;
        if (isVersusPage) {
          setPageData(templateData);
          setShowErrorMessage('');
        } else {
          setPageData(null);
          setShowErrorMessage('The selected page is not a valid CC versus page. Please check the ID or the actual page settings.');
        }
      });
    } catch {
      setShowErrorMessage('Error fetching the page data. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  const addNewReplacementCard = () => {
    setItemsToReplicate((prev) => [...prev, {
      card_1: firstCard,
      card_2: secondCard
    }]);
  };

  const removeReplacementCard = (indexToRemove) => {
    setItemsToReplicate((prev) => prev.filter((item, index) => index !== indexToRemove));
  };

  const handleSelectChange = (selectedOption, updateState, originalCard) => {
    const selectedCard = availableCards.find(item => item.id === parseInt(selectedOption.value, 10));

    updateState({
      originalValue: parseInt(originalCard.id, 10),
      originalFullName: originalCard.name,
      originalShortName: originalCard.short_name,
      newValue: parseInt(selectedOption.value, 10),
      newName: selectedCard.short_name || selectedOption.label,
      image: selectedCard.card_image?.url
    });
  };

  return (
    <Flex flexDirection="column" alignItems="center" h="full">
      <ProgressBar processName="pageDuplication" currentStep={0} />
      <Heading as="h1" textAlign="center" mb={10}>Select Template Page</Heading>
      <FormControl borderWidth="1px" rounded="lg" shadow="1px 1px 3px rgba(0,0,0,0.3)" p={6}>
        <Flex mb={6}>
          <FormLabel htmlFor="environment" mb="0">
            Please select the environment you want to work on:
          </FormLabel>
          <RadioGroup
            id="environment"
            defaultValue={environment}
            onChange={(event) => setEnvironment(event)}>
            <Stack direction="row" gap={3}>
              <Radio value="dev">Development</Radio>
              <Radio value="prod">Production</Radio>
            </Stack>
          </RadioGroup>
        </Flex>
        <Flex alignItems="flex-end" justifyContent="center" gap="5%" mb={6}>
          <Box w="15%">
            <FormLabel htmlFor="page-id" mb={1}>
              Page ID
            </FormLabel>
            <Input id="page-id" value={pageId} min={1} onChange={(e) => setPageId(e.target.value)}>
            </Input>
          </Box>
          <Button
            variant="solid"
            colorScheme="gray"
            isLoading={isProcessing}
            onClick={() => handlePageSubmit()}>
            Confirm Page
          </Button>
        </Flex>
        {showErrorMessage && (
          <Alert status="error">
            <AlertIcon />
            <AlertTitle>Process error:</AlertTitle>
            <AlertDescription>{showErrorMessage}</AlertDescription>
          </Alert>
        )}
        {pageData && !showErrorMessage && (<>
          <Divider mb={6} />
          {itemsToReplicate.map((card, index) => (
            <Flex key={`replace-pair-${index}`} alignItems="flex-end" justifyContent="space-between" gap="2%" mb={6}>
              <Input isReadOnly={true} value={card.card_1?.newName}/>
              <Button
                variant="solid"
                colorScheme="red"
                onClick={() => removeReplacementCard(index)}
              >
                <Icon as={FiMinusCircle} />
              </Button>
              <Input isReadOnly={true} value={card.card_2?.newName}/>
            </Flex>
          ))}
          <Flex
            alignItems="flex-end"
            justifyContent="space-between"
            gap="2%"
            mb={6}
            sx={{
              '> div': {
                flex: 1
              }
            }}
          >
            <Box>
              <FormLabel htmlFor="selected-card-1" mb={1}>
                Replace {pageData.versus_cards?.card_1?.name} for:
              </FormLabel>
              <Select
                id="selected-card-1"
                isSearchable={true}
                onChange={(e) => handleSelectChange(e, setFirstCard, pageData.versus_cards?.card_1)}
                options={availableCards.map(card => ({ value: card.id, label: card?.name }))}
              />
            </Box>
            <Button
              variant="solid"
              colorScheme="teal"
              isDisabled={!firstCard || !secondCard}
              onClick={() => addNewReplacementCard()}
            >
              <Icon as={FiPlusCircle} />
            </Button>
            <Box>
              <FormLabel htmlFor="selected-card-2" mb={1}>
                Replace {pageData.versus_cards?.card_2?.name} for:
              </FormLabel>
              <Select
                id="selected-card-2"
                isSearchable={true}
                onChange={(e) => handleSelectChange(e, setSecondCard, pageData.versus_cards?.card_2)}
                options={availableCards.map(card => ({ value: card.id, label: card.name }))}
              />
            </Box>
          </Flex>
        </>)}
      </FormControl>
      <Confirmation
        linkTo="/components/static/cc"
        buttonLabel="Next step"
        bodyDescription={`Please check selected credit cards to replace before moving on.\n
        Cards to replace: ${itemsToReplicate.length}.`}
        shouldBeDisabled={!Boolean(itemsToReplicate.length)} />
    </Flex>
  );
};
