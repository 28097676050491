import axios from 'axios';
import qs from 'qs';
import { STATES_LIST } from './constants';
import ccVersusBackground from '../images/cc-versus-background.png';
import ccVersusCircle from '../images/cc-versus-circle.png';

const replaceIgnoreCase = (originalString, search, replacement) => {
  if (!originalString) {
    return null
  }

  const regex = new RegExp(search, 'g');
  const result = originalString.replace(regex, replacement);
  
  return result;
};

const replaceMultipleStrings = (inputString, replacements, lengthLimit = null) => {
  if (inputString && typeof inputString !== 'string') {
    return inputString;
  } else if (!inputString || inputString.trim() === '' || replacements?.key) {
    return null;
  }

  const escapedKeys = Object.keys(replacements).map(key => key.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1"));
  const regex = new RegExp(escapedKeys.join('|'), 'g');
  const newString = inputString.replace(regex, (match) => replacements[match]);
  
  return lengthLimit ? newString.substring(0, lengthLimit) : newString;
};

const getReplacements = (originalValues, replaceItem, operation, customReplacements = {}) => {
  const { state, city, company, model } = originalValues;
  const abbeviatedState = STATES_LIST[state];

  if (operation === 'state') {
    return {
      ...customReplacements,
      [state]: replaceItem,
      [state.toLowerCase()]: replaceItem,
      [abbeviatedState]: STATES_LIST[replaceItem]
    };
  } else if (operation === 'city') {
    return {
      ...customReplacements,
      [state]: replaceItem.state,
      [state.toLowerCase()]: replaceItem.state,
      [city]: replaceItem.city,
      [city.toLowerCase()]: replaceItem.city,
      [abbeviatedState]: STATES_LIST[replaceItem.state]
    };
  } else if (operation === 'company') {
    return {
      ...customReplacements,
      [company.label]: replaceItem.label,
      [company.label.toLowerCase()]: replaceItem.label
    };
  } else if (operation === 'model') {
    return {
      ...customReplacements,
      [model]: replaceItem,
      [model.toLowerCase()]: replaceItem,
      [model.replaceAll(' ', '-').toLowerCase()]: replaceItem.replaceAll(' ', '-').toLowerCase()
    };
  }
};

// const cleanRelationFieldsV4 = (input) => {
//   const isObject = input && typeof input === 'object';
//
//   if (Array.isArray(input)) {
//     input = input.map(item => cleanRelationFields(item));
//   } else if (isObject && input['data'] && Array.isArray(input['data'])) {
//     input = input['data'].map(item => cleanRelationFields(item));
//   } else if (isObject && Object.keys.length === 1 && 'data' in input && input['data'] === null) {
//     input = null;
//   } else if (isObject) {
//     if (input['id'] && input['attributes']) {
//       input = input['id'];
//     } else if (input['data'] && input['data']['id'] && input['data']['attributes']) {
//       input = input['data']['id'];
//     } else {
//       delete input['id'];
//       for (const key in input) {
//         if (input[key] && typeof input[key] === 'object' && input[key]['data'] && input[key]['data']['id'] && input[key]['data']['attributes']) {
//           input[key] = input[key]['data']['id'];
//         } else if (typeof input[key] === 'object') {
//           input[key] = cleanRelationFields(input[key]);
//         }
//       }
//     }
//   }
//
//   return input;
// };
const cleanRelationFields = (input) => {
  const isObject = input && typeof input === 'object';
  if (Array.isArray(input)) {
    input = input.map(item => cleanRelationFields(item));
  }else if (isObject) {
    if (input['documentId']) {
      if(input["mime"]){
        input = input['id'];
      }else{
        input = input['documentId'];
      }
    } else {
      delete input['id'];
      for (const key in input) {
        if(input[key]?.["mime"] && input[key]['id']){
          input[key] = input[key]['id'];
        }else if (input[key] && typeof input[key] === 'object' && input[key]['documentId'] ) {
          input[key] = input[key]['documentId'];
        } else if (typeof input[key] === 'object') {
          input[key] = cleanRelationFields(input[key]);
        }
      }
    }
  }

  return input;
};

const replaceOnFlexComponent = (obj, targetWord, replacement) => {
  if (typeof obj === 'string') {
    obj = obj.replace(new RegExp(`\\b${targetWord}\\b`, 'gi'), replacement);
  } else {
    if (Array.isArray(obj)) {
      for (let i = 0; i < obj.length; i++) {
        if (typeof obj[i] === 'object') {
          replaceOnFlexComponent(obj[i], targetWord, replacement);
        } else if (typeof obj[i] === 'string') {
          obj[i] = obj[i].replace(new RegExp(`\\b${targetWord}\\b`, 'gi'), replacement);
        }
      }
    } else if (obj !== null) {
      for (const key in obj) {
        if (typeof obj[key] === 'string') {
          obj[key] = obj[key].replace(new RegExp(`\\b${targetWord}\\b`, 'gi'), replacement);
        } else {
          replaceOnFlexComponent(obj[key], targetWord, replacement);
        }
      }
    }
  }

  return obj;
};

const getUpdatedDbData = async (queryToExecute, config) => {
  if (!queryToExecute) {
    return [];
  }

  try {
    const response = await axios.post(`${config.api}/rds-files/run`, { query: queryToExecute }, {
      headers: {
        'x-api-key': config.apiKey
      }
    });

    return response?.data?.data;
  } catch(e) {
    return [];
  }
};

const transformToIntValue = (value) => {
  const formattedValue = typeof value === 'string' ? value.replace(/[^0-9.]/g, '') : value;

  return parseInt(formattedValue, 10);
};

const getUpdatedWinnerCompany = async ({data, mainColumn, referenceColumn, filters = null, config}) => {
  try {
    const filteredData = filters ? data.filter(dataItem => Object.keys(filters).every(filterItem => dataItem[filterItem] === filters[filterItem].selected)) : data;
    const cheapestCompany = filteredData.reduce((min, current) => {
      const currentValue = transformToIntValue(current[mainColumn]);
      const minValue = transformToIntValue(min[mainColumn]);
      return currentValue < minValue ? current : min;
    }, filteredData[0]);

    if (!cheapestCompany) {
      return null;
    }

    const query = qs.stringify({
      filters: {
        Name: {
          $eq:
            cheapestCompany.Company ||
            cheapestCompany.company ||
            cheapestCompany.provider ||
            cheapestCompany.consolidated_issuer_name ||
            cheapestCompany[referenceColumn]
        }
      }
    }, {
      encodeValuesOnly: true
    });

    if (!query) {
      return null;
    }

    const response = await axios.get(`${config.strapiUrl}/api/companies?${query}`, {
      headers: {
        Authorization: `Bearer ${config.token}`
      }
    });

    if (response?.data?.data?.length > 0) {
      return response.data.data[0].id;
    } else {
      return null;
    }
  } catch(e) {
    return null;
  }
};

const formatDate = (dateToTransform, format = 'YYYY-MM-DD') => {
  const year = dateToTransform.getFullYear();
  const month = dateToTransform.getMonth() + 1;
  const day = dateToTransform.getDate();
  
  if (format === 'DD-MM-YYYY') {
    return `${day < 10 ? '0' : ''}${day}-${month < 10 ? '0' : ''}${month}-${year}`;
  } else if (format === 'MM-DD-YYYY') {
    return `${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}-${year}`;
  }
  
  return `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
};

const getFirstItemFromArray = (dataArray, defaultValue = null) => {
  if (dataArray && Array.isArray(dataArray) && dataArray.length > 0) {
    return dataArray[0];
  } else {
    return defaultValue;
  }
};

const getErrorMessage = (error) => {
  const errorObject = error.response?.data?.error;

  if (typeof error === 'string') {
    return error;
  } else if (errorObject && errorObject?.status === 'UNAUTHENTICATED') {
    return 'Invalid authentication credentials, please log out and log in again.';
  } else if (errorObject && errorObject?.status === 'INVALID_ARGUMENT') {
    return 'The file already has a Sherlock Results tab, please remove the current tab and try again.';
  } else if (typeof errorObject === 'string') {
    return error.response.data.message;
  } else if (error.message) {
    return error.message;
  } else {
    return 'Please try again.';
  }
};

const findMatchingObject = (parentObject, matchingLogic) => {
  for (const key in parentObject) {
    if (matchingLogic(parentObject[key])) {
      return key;
    }
  }

  return null;
};

const formatToPermalink = (value) => {
  return value
  .toLowerCase() // Convert the string to lowercase
  .replace(/\s+/g, '-') // Replace spaces with hyphens
  .replace(/[^\w\-]+/g, '') // Remove non-word characters (except hyphens)
  .replace(/\-\-+/g, '-') // Replace multiple hyphens with a single hyphen
  .replace(/^-+/, '') // Trim hyphens from the beginning of the string
  .replace(/-+$/, ''); // Trim hyphens from the end of the string
};

const removeDuplicates = (arr, columnName = '') => {
  const duplicates = [];
  const uniques = arr.reduce((acc, curr) => {
    const columnToCompare = columnName || 0;
    if (!acc.find(item => item[columnToCompare] === curr[columnToCompare])) {
      acc.push(curr);
    } else {
      duplicates.push(curr);
    }
    return acc;
  }, []);

  return { duplicates, uniques };
};

const getSpreadsheetId = (url) => {
  const regex = /\/d\/([a-zA-Z0-9-_]+)\//;
  const match = url.match(regex);
  return match ? match[1] : '';
};

const arrayToCSV = (data, customColumns) => {
  const columns = Object.keys(data[0]);
  const csvRows = [];
  let keys = columns;

  if (Boolean(customColumns.length)) {
    const customKeys = [];
    // Add preferred columns in order if they exist in the current object
    customColumns.forEach(key => {
      if (columns.includes(key)) {
        customKeys.push(key);
      }
    });

    // Add remaining columns (those not in prefColumns)
    columns.forEach(key => {
      if (!customColumns.includes(key)) {
        customKeys.push(key);
      }
    });

    keys = customKeys;
  }

  // Add header row
  csvRows.push(keys.join(','));

  // Add data rows
  for (const row of data) {
    csvRows.push(keys.map(key => JSON.stringify(row[key] || '')).join(','));
  }

  return csvRows.join('\n');
};

const combineCreditCardImages = async (
  image1Url: string,
  image1Name: string,
  image2Url: string,
  image2Name: string
): Promise<Blob> => {
  if (!image1Url || !image2Url) {
    return null;
  }

  const loadImage = (url: string): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = url;
      img.onload = () => resolve(img);
      img.onerror = (err) => reject(err);
    });
  };

  const [background, vsCircle, image1, image2] = await Promise.all([
    loadImage(ccVersusBackground),
    loadImage(ccVersusCircle),
    loadImage(image1Url),
    loadImage(image2Url)
  ]);

  const imageWidth = 369;
  const imageHeight = 234;
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('Failed to get canvas context');
  }

  canvas.width = background.width;
  canvas.height = background.height;
  ctx.font = '28px Arial';
  ctx.fillStyle = '#292929';

  ctx.drawImage(background, 0, 0);
  ctx.drawImage(image1, 197, 161, imageWidth, imageHeight);
  ctx.drawImage(image2, 634, 161, imageWidth, imageHeight);
  ctx.drawImage(vsCircle, 540, 220);

  const wrapText = (text: string, x: number, y: number, maxWidth: number, lineHeight: number) => {
    const words = text.split(' ');
    let line = '';
    const lines = [];

    for (let n = 0; n < words.length; n++) {
    const testLine = line + words[n] + ' ';
    const metrics = ctx.measureText(testLine);
    if (metrics.width > maxWidth && n > 0) {
      lines.push(line);
      line = words[n] + ' ';
    } else {
      line = testLine;
    }
    }
    lines.push(line);

    lines.forEach((line, index) => {
      const metrics = ctx.measureText(line);
      const lineX = x + (maxWidth - metrics.width) / 2;
      ctx.fillText(line, lineX, y + index * lineHeight);
    });
  };

  wrapText(image1Name, 197, 443, imageWidth, 30);
  wrapText(image2Name, 634, 443, imageWidth, 30);

  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      if (blob) {
        resolve(blob);
      } else {
        throw new Error(`Failed to create combined image for ${image1Name} Vs. ${image2Name}`);
      }
    });
  });
};

export {
  replaceIgnoreCase,
  replaceMultipleStrings,
  getReplacements,
  cleanRelationFields,
  replaceOnFlexComponent,
  getUpdatedDbData,
  getUpdatedWinnerCompany,
  formatDate,
  getFirstItemFromArray,
  getErrorMessage,
  findMatchingObject,
  formatToPermalink,
  removeDuplicates,
  getSpreadsheetId,
  arrayToCSV,
  combineCreditCardImages
};
