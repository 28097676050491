'use client'

import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import SearchSelect from 'react-select';
import {
  Flex,
  Box,
  Text,
  Heading,
  FormControl,
  FormLabel,
  Switch,
  Input,
  Select,
  Button,
  Divider,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  RadioGroup,
  Radio,
  Stack,
  Icon
} from '@chakra-ui/react';
import { FiPlusCircle, FiMinusCircle } from 'react-icons/fi';
import { useGeneralContext } from '../../App';
import { COLLECTIONS_LIST, PAGE_CONFIG, MODELS_LIST, MONTHS_LIST } from '../../utils/constants';
import { ProgressBar } from '../../components/ProgressBar';
import { Confirmation } from '../../components/Confirmation';

export const SelectModelFlex = () => {
  const [pageId, setPageId] = useState<string>('');
  const [isInvalidUrlPattern, setIsInvalidUrlPattern] = useState<boolean>(true);
  const [replacementModel, setReplacementModel] = useState<any>(null);
  const [replacementModels, setReplacementModels] = useState<Array<any>>([]);
  const [isReadyToProcess, setIsReadyToProcess] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const navigate = useNavigate();

  const {
    config,
    environment,
    setEnvironment,
    collection,
    setCollection,
    setOperationType,
    modelToProcess,
    setModelToProcess,
    pageData,
    setPageData,
    urlPattern,
    setUrlPattern,
    itemsToReplicate,
    setItemsToReplicate,
    setResultPages,
    setCurrentProcess,
    monthToReplace,
    setMonthToReplace,
    currentDateFilter,
    setCurrentDateFilter,
    newMonth,
    setNewMonth,
    newDateFilter,
    setNewDateFilter,
    modifyPageDate,
    setModifyPageDate
  } = useGeneralContext();

  useEffect(() => {
    setDateValues();
    setEnvironment('dev');
    setCollection('STRAPI_AUTO_OTHER');
    setPageId('');
    setUrlPattern('');
    setResultPages([]);
    setCurrentProcess('pageDuplication');
    setOperationType('model');
  }, []);

  useEffect(() => {
    config.current = PAGE_CONFIG[environment];
  }, [environment]);

  useEffect(() => {
    setPageData(null);
    setErrorMessage('');
    setItemsToReplicate([]);
    setUrlPattern('');
    setModelToProcess('');
  }, [environment, collection, pageId]);

  useEffect(() => {
    setIsReadyToProcess(pageData && !isInvalidUrlPattern && Boolean(itemsToReplicate.length));
  }, [pageData, isInvalidUrlPattern, itemsToReplicate]);

  useEffect(() => {
    setIsInvalidUrlPattern(urlPattern.includes(' ') || !urlPattern?.startsWith('/') || !urlPattern?.endsWith('/') || !urlPattern?.includes('{{model}}'));
  }, [urlPattern]);

  useEffect(() => {
    setReplacementModels(MODELS_LIST.filter(model => model !== modelToProcess && !itemsToReplicate.includes(model)));
  }, [modelToProcess, itemsToReplicate]);

  const setDateValues = () => {
    const currentDate = new Date();
    const nextMonth = new Date();
    nextMonth.setMonth(currentDate.getMonth() + 1);
    setMonthToReplace(currentDate.toLocaleString('en-US', { month: 'long' }));
    setNewMonth(nextMonth.toLocaleString('en-US', { month: 'long' }));

    currentDate.setDate(1);
    currentDate.setMonth(currentDate.getMonth() - 2);
    setCurrentDateFilter(currentDate);

    nextMonth.setDate(1);
    nextMonth.setMonth(nextMonth.getMonth() - 2);
    setNewDateFilter(nextMonth);
  };

  const handlePageSubmit = async () => {
    setIsProcessing(true);
    try {
      const request = await axios.get(`${config.current.strapiUrl}/api/${COLLECTIONS_LIST[collection].api}/${pageId}?status=draft&pLevel=6`, {
        headers: {
          Authorization: `Bearer ${config.current.token}`
        }
      });
      
      const templateData = request.data?.data;
      const isValidPermalink = templateData.publishing.permalink.includes(modelToProcess.replaceAll(" ", "-").toLowerCase());
      const isValidPageTitle = templateData.name?.includes(modelToProcess) || templateData.banner?.title?.includes(modelToProcess);
      if (!templateData) {
        setPageData(null);
        setErrorMessage('There is no data for the selected page. Please try again later.');
      } else if (!isValidPermalink && !isValidPageTitle) {
        setPageData(null);
        setErrorMessage('The page does not correspond to the selected car model. Please check the value and try again.');
      } else {
        setPageData(templateData);
        setErrorMessage('');
      }
    } catch {
      setErrorMessage(`It was not possible to identify the page #${pageId} for the collection: ${COLLECTIONS_LIST[collection].name}. Please check collection name and ID.`);
    } finally {
      setIsProcessing(false);
    }
  };

  const addReplacementModel = () => {
    setItemsToReplicate(prev => [...prev, replacementModel]);
    setReplacementModel(null);
  };

  const handleProcess = () => {
    setUrlPattern(urlPattern.replace('{{model}}', modelToProcess.replaceAll(' ', '-').toLowerCase()));
    navigate('/components/static');
  };

  const renderDateReplacer = () => {
    return (<>
      <Flex alignItems="center" gap={3} mb={6}>
        <Text fontWeight="semibold">
          Modify date values?
        </Text>
        <Switch
          id="modify-dates"
          isChecked={modifyPageDate}
          onChange={(event) => setModifyPageDate(event.target.checked)}
        />
      </Flex>
      {modifyPageDate && (
        <Flex alignItems="flex-start" justifyContent="center" gap="5%" mb={6} h="68px">
          <Box>
            <FormLabel htmlFor="current-month" mb={1}>
              Month to replace
            </FormLabel>
            <Select id="current-month" value={monthToReplace} onChange={(e) => setMonthToReplace(e.target.value)}>
              {MONTHS_LIST.map((item, index) => <option key={`replace-month-${index}`} value={item}>
                {item}
              </option>)}
            </Select>
          </Box>
          <Box>
            <FormLabel htmlFor="current-date" mb={1}>
              Current date filter value
            </FormLabel>
            <DatePicker
              id="current-date"
              dateFormat="yyyy-MM-dd"
              selected={currentDateFilter}
              onChange={(date) => setCurrentDateFilter(date)} />
          </Box>
          <Divider orientation="vertical" />
          <Box>
            <FormLabel htmlFor="new-month" mb={1}>
              New month value
            </FormLabel>
            <Select id="new-month" value={newMonth} onChange={(e) => setNewMonth(e.target.value)}>
              {MONTHS_LIST.map((item, index) => <option key={`new-month-${index}`} value={item}>
                {item}
              </option>)}
            </Select>
          </Box>
          <Box>
            <FormLabel htmlFor="new-date" mb={1}>
              New date filter value
            </FormLabel>
            <DatePicker
              id="new-date"
              dateFormat="yyyy-MM-dd"
              selected={newDateFilter}
              onChange={(date) => setNewDateFilter(date)} />
          </Box>
        </Flex>
      )}
    </>);
  };

  return (
    <Flex flexDirection="column" alignItems="center" h="full">
      <ProgressBar processName="pageDuplication" currentStep={0} />
      <Heading as="h1" textAlign="center" mb={10}>Select Template Page</Heading>
      <FormControl borderWidth="1px" rounded="lg" shadow="1px 1px 3px rgba(0,0,0,0.3)" p={6}>
        <Flex mb={6}>
          <FormLabel htmlFor="environment" mb="0">
            Please select the environment you want to work on:
          </FormLabel>
          <RadioGroup
            id="environment"
            value={environment}
            onChange={(event) => setEnvironment(event)}>
            <Stack direction="row" gap={3}>
              <Radio value="dev">Development</Radio>
              <Radio value="prod">Production</Radio>
            </Stack>
          </RadioGroup>
        </Flex>
        <Flex alignItems="flex-end" justifyContent="space-between" gap="5%" mb={6}>
          <Box w="25%">
            <FormLabel htmlFor="collection" mb={1}>
              Collection Name
            </FormLabel>
            <Select id="collection" value={collection} onChange={(e) => setCollection(e.target.value)}>
              {Object.keys(COLLECTIONS_LIST).map((item, index) => <option key={`collection-${index}`} value={item}>
                {COLLECTIONS_LIST[item].name}
              </option>)}
            </Select>
          </Box>
          <Box w="10%">
            <FormLabel htmlFor="page-id" mb={1}>
              Page ID
            </FormLabel>
            <Input id="page-id" value={pageId} min={1} onChange={(e) => setPageId(e.target.value)}>
            </Input>
          </Box>
          <Box w="35%">
            <FormLabel htmlFor="selected-model" mb={1}>
              Model to process
            </FormLabel>
            <SearchSelect
              id="selected-model"
              isSearchable={true}
              isClearable={true}
              value={modelToProcess ? { label: modelToProcess, value: modelToProcess } : null}
              onChange={({value}) => setModelToProcess(value)}
              options={MODELS_LIST.sort().map(model => ({ value: model, label: model }))}
            />
          </Box>
          <Button
            w="15%"
            variant="solid"
            colorScheme="gray"
            isLoading={isProcessing}
            isDisabled={!modelToProcess}
            onClick={() => handlePageSubmit()}>
            Confirm Page
          </Button>
        </Flex>
        {errorMessage && (
          <Alert status="error">
            <AlertIcon />
            <AlertTitle>Data error:</AlertTitle>
            <AlertDescription>
              {errorMessage}
            </AlertDescription>
          </Alert>
        )}
        {pageData && modelToProcess && (<>
          <Divider mb={6} />
          {renderDateReplacer()}
          <Divider mb={6} />
          <Text fontWeight="semibold" mb={6}>
            {`Please use {{model}} on the URL pattern to create the new permalink structure.`}
          </Text>
          <Box mb={6}>
            <FormLabel htmlFor="base-url" mb={1}>
              URL Pattern
            </FormLabel>
            <Input
              id="base-url"
              isInvalid={isInvalidUrlPattern}
              errorBorderColor="red.300"
              value={urlPattern}
              onChange={(e) => setUrlPattern(e.target.value)}
            />
          </Box>
          <Flex gap={2} mb={6}>
            <Text fontWeight="semibold">
              New permalink estructure:
            </Text>
            {urlPattern && (
              <Text fontWeight="semibold" color={isInvalidUrlPattern ? 'red.300' : '#2F855A'}>
                {urlPattern.replace('{{model}}', modelToProcess?.replaceAll(' ', '-').toLowerCase())}
              </Text>
            )}
          </Flex>
          <Divider mb={6} />
          <Box>
            <Alert status="success" mb={6}>
              <AlertIcon />
              <AlertTitle>Success:</AlertTitle>
              <AlertDescription>{`A page for ${modelToProcess} was found on the ${collection} collection`}</AlertDescription>
            </Alert>
            <Flex alignItems="flex-end" justifyContent="space-between" gap="2%" mb={6}>
              <Box flex={1}>
                <FormLabel htmlFor="selected-model" mb={1}>
                  Replace {modelToProcess} for:
                </FormLabel>
                <SearchSelect
                  id="selected-model"
                  isSearchable={true}
                  isClearable={true}
                  value={replacementModel ? { label: replacementModel, value: replacementModel } : null}
                  onChange={({value}) => setReplacementModel(value)}
                  options={replacementModels.sort().map(model => ({ value: model, label: model }))}
                />
              </Box>
              <Button
                variant="solid"
                colorScheme="teal"
                isDisabled={!replacementModel}
                onClick={() => addReplacementModel()}
              >
                <Icon as={FiPlusCircle} />
              </Button>
            </Flex>
            {itemsToReplicate.map((model, index) => (
              <Flex key={`replace-model-${index}`} alignItems="flex-end" justifyContent="space-between" gap="2%" mb={6}>
                <Input isReadOnly={true} value={model}/>
                <Button
                  variant="solid"
                  colorScheme="red"
                  onClick={() => setItemsToReplicate(prev => prev.filter(item => item !== model))}
                >
                  <Icon as={FiMinusCircle} />
                </Button>
              </Flex>
            ))}
          </Box>
        </>)}
      </FormControl>
      <Confirmation
        buttonLabel="Next step"
        bodyDescription={`Please check selected template page and car models to duplicate before moving on.\n
        Template page: ${modelToProcess}.\n
        Number of copies to create: ${itemsToReplicate.length}`}
        shouldBeDisabled={!isReadyToProcess}
        onClickEvent={() => handleProcess()} />
    </Flex>
  );
};
